define("backalley/pods/products/edit/route", ["exports", "backalley/models/product", "backalley/models/product_amendment", "backalley/models/category", "backalley/models/color", "backalley/models/tag", "backalley/models/manufacturer", "backalley/models/boutique", "backalley/mixins/category-search", "backalley/config/environment", "backalley/util/escape-regex", "backalley/util/product", "backalley/models/product-delivery-tag", "backalley/models/delivery-modifier", "backalley/enums/deliveryModifierType", "backalley/enums/fieldExcludedFromAutomation"], function (_exports, _product, _product_amendment, _category, _color, _tag, _manufacturer, _boutique, _categorySearch, _environment, _escapeRegex, _product2, _productDeliveryTag, _deliveryModifier, _deliveryModifierType, _fieldExcludedFromAutomation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function pluck(arr = [], value) {
    return arr.map(iteration => Ember.get(iteration, value));
  }

  const FALLBACK_HS_CODE = 69120085;

  var _default = Ember.Route.extend(_categorySearch.default, {
    serialize(model) {
      return {
        product_id: model._id
      };
    },

    afterModel(model) {
      const [stock_line] = model.product.stock_lines;
      const {
        boutique: boutiqueId
      } = stock_line;
      const shippingCostAddition = model.product.shipping_cost_addition || {
        amount: 0,
        currency: 'GBR'
      };
      Ember.set(model.product, 'shipping_cost_addition', _objectSpread(_objectSpread({}, shippingCostAddition), {}, {
        amount_shown: shippingCostAddition.amount / 100
      }));
      return Ember.RSVP.hash({
        amendments: _product_amendment.default.findByProduct(Ember.get(model, 'product._id')),
        boutiques: _boutique.default.find({
          _id: boutiqueId,
          fields: 'name,published'
        })
      }).then(({
        amendments,
        boutiques
      }) => {
        Ember.setProperties(model, {
          productAmendments: Ember.get(amendments, 'product_amendments'),
          fieldAmendments: Ember.get(amendments, 'field_product_amendments')
        });
        const [boutique] = boutiques;
        Ember.set(model.product, 'boutique', boutique);
      });
    },

    async model({
      product_id
    }) {
      let {
        insideTask,
        taskData,
        totalCount
      } = this.modelFor('products');
      const weightBrackets = await getWeightBracketNames();
      return getProduct(product_id).then(product => {
        return Ember.RSVP.hash({
          product,
          user: this.get('session.user'),
          trouvaWebURL: _environment.default.trouvaWebURL,
          shippingPolicies: ['normal', 'unshippable', 'uk_only', 'eu_incl_uk_only'],
          weightBrackets: weightBrackets,
          imageFolder: `brand/${product.brand}`,
          categoriesTrees: getCategoriesTrees(product.populatedCategories),
          autocomplete: {},
          fieldAmendments: {},
          insideTask,
          taskData,
          totalCount,
          actions: {
            changeGalleryThumbnail: changeGalleryThumbnail.bind(this),
            addGalleryImage: addGalleryImage.bind(this),
            removeGalleryImage: removeGalleryImage.bind(this),
            reorderGallery: reorderGallery.bind(this),
            autocompleteCategories: autocompleteCategories.bind(this),
            selectCategory: selectCategory.bind(this),
            removeCategory: removeCategory.bind(this),
            autocompleteColors: autocompleteColors.bind(this),
            selectColor: selectColor.bind(this),
            removeColor: removeColor.bind(this),
            selectShippingPolicy: selectShippingPolicy.bind(this),
            selectWeightBracket: selectWeightBracket.bind(this),
            autocompleteTags: autocompleteTags.bind(this),
            selectTag: selectTag.bind(this),
            removeTag: removeTag.bind(this),
            autocompleteDeliveryTags: autocompleteDeliveryTags.bind(this),
            selectDeliveryTag: selectDeliveryTag.bind(this),
            removeDeliveryTag: removeDeliveryTag.bind(this),
            autocompleteManufacturers: autocompleteManufacturers.bind(this),
            selectManufacturer: selectManufacturer.bind(this),
            removeManufacturer: removeManufacturer.bind(this),
            setQS: setQS.bind(this),
            setGTIN: setGTIN.bind(this),
            setShippingCostAddition: setShippingCostAddition.bind(this),
            setExperimentQuantity: setExperimentQuantity.bind(this),
            save: save.bind(this),
            edit: edit.bind(this),
            view: view.bind(this),
            saveAndNext: saveAndNext.bind(this),
            detachFromFeed: detachFromFeed.bind(this)
          }
        });
      });
    },

    _detachFromFeed: detachFromFeed,
    actions: {
      createAmendment(product, attribute) {
        const amendment = _product_amendment.default.create({
          attribute,
          product,
          status: 'pending',
          _edit: true
        });

        Ember.set(this, `currentModel.fieldAmendments.${attribute}`, amendment);
      },

      saveAmendment(amendment) {
        let task = this.paramsFor('products').task;
        return amendment.save({
          task
        });
      },

      deleteAmendment(amendment) {
        // Amendment is stored on mongo
        if (Ember.get(amendment, '_id')) {
          return amendment.delete().then(() => {
            Ember.set(this, `currentModel.fieldAmendments.${Ember.get(amendment, 'attribute')}`, undefined);
          });
        } else {
          // This is a new *unsaved* amendment
          Ember.set(this, `currentModel.fieldAmendments.${Ember.get(amendment, 'attribute')}`, undefined);
        }
      },

      setAmendmentOnField(amendment) {
        Ember.set(this, `currentModel.fieldAmendments.${Ember.get(amendment, 'attribute')}`, amendment);
      }

    }
  });

  _exports.default = _default;

  function getProduct(_id) {
    return _product.default.find({
      _id,
      populate: 'categories colors tags delivery_tags boutiques.boutique standard_manufacturer stock_lines.boutique',
      attach_related: 'stock_lines'
    }).then(products => {
      let product = products[0];
      product.set('populatedTags', product.tags);
      product.set('tags', pluck(product.tags, '_id'));
      product.set('populatedDeliveryTags', product.delivery_tags);
      product.set('delivery_tags', pluck(product.delivery_tags, '_id'));
      product.set('populatedColors', product.colors);
      product.set('colors', pluck(product.colors, '_id'));
      product.set('populatedCategories', product.categories);
      product.set('categories', pluck(product.categories, '_id'));
      product.set('populatedBoutiques', [...new Set(pluck(product.stock_lines, 'boutique'))]);
      product.set('populatedManufacturer', product.standard_manufacturer);
      product.set('standard_manufacturer', Ember.get(product, 'standard_manufacturer_id'));
      product.set('currentShippingPolicy', product.shipping_policy);
      product.set('initialWeightBracket', product.weight_bracket);
      product.set('currentWeightBracket', product.weight_bracket);
      product.set('currentFieldsExcludedFromAutomation', product.fields_excluded_from_automation);
      const isWeightBracketExcludedFromAutomation = (0, _product2.fieldIsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, product.fields_excluded_from_automation);
      product.set('isWeightBracketExcludedFromAutomation', isWeightBracketExcludedFromAutomation);
      product.set('isVisibleIncludeWeightBracketAutomation', isWeightBracketExcludedFromAutomation);
      return product;
    });
  }

  function changeGalleryThumbnail(thumbnail) {
    const oldThumbnail = Ember.get(this, 'currentModel.product.thumbnail');
    const images = Ember.get(this, 'currentModel.product.images');
    images.addObject(oldThumbnail);
    Ember.set(this, 'currentModel.product.thumbnail', thumbnail);
  }

  function addGalleryImage(image) {
    const images = Ember.get(this, 'currentModel.product.images');
    images.addObject(image);
  }

  function removeGalleryImage(image) {
    const images = Ember.get(this, 'currentModel.product.images');
    images.removeObject(image);
  }

  function reorderGallery(photos) {
    Ember.set(this, 'currentModel.product.images', photos);
  }

  function getCategoriesTrees(categories) {
    return Ember.RSVP.all(categories.map(getCategoryTree));
  }

  function getCategoryTree(category) {
    if (category.isMacro) {
      return Ember.RSVP.hash({
        macro: _category.default.create(category)
      });
    } else if (category.isParent) {
      return Ember.RSVP.hash({
        macro: _category.default.findById(category.macro),
        parent: _category.default.create(category)
      });
    } else {
      return Ember.RSVP.hash({
        macro: _category.default.findById(category.macro),
        parent: _category.default.findById(category.parent),
        child: _category.default.create(category)
      });
    }
  }

  function getWeightBracketNames() {
    return _deliveryModifier.default.find({
      type: _deliveryModifierType.default.WEIGHT_BRACKET
    }).then(({
      deliveryModifiers
    }) => {
      return deliveryModifiers.map(deliveryModifier => deliveryModifier.name);
    });
  }

  function autocompleteCategories(q) {
    if (Ember.isEmpty(q)) return;
    return this.searchCategories(q) // part of category-search mixin
    .then(res => {
      this.set('currentModel.autocomplete.categories', res);
    });
  }

  function selectCategory(category) {
    if (!this.currentModel.product.hs_code) {
      Ember.set(this.currentModel.product, 'hs_code', {});
    }

    Ember.set(this.currentModel.product, 'hs_code.default', category.hs_code || FALLBACK_HS_CODE);
    getCategoryTree(category).then(tree => {
      this.get('currentModel.categoriesTrees').addObject(tree);
    });
  }

  function autocompleteManufacturers(q) {
    if (Ember.isEmpty(q)) return;
    return _manufacturer.default.find({
      $text: {
        $search: q,
        $language: 'en'
      },
      limit: 10,
      archived: {
        $ne: true
      }
    }).then(res => {
      this.set('currentModel.autocomplete.manufacturers', res);
    });
  }

  function selectManufacturer(manufacturer) {
    this.set('currentModel.product.populatedManufacturer', manufacturer);
  }

  function removeManufacturer() {
    this.set('currentModel.product.populatedManufacturer', null);
  }

  function removeCategory(category) {
    this.get('currentModel.categoriesTrees').removeObject(category);
  }

  function autocompleteColors(q) {
    if (Ember.isEmpty(q)) return;
    return _color.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    }).then(colors => this.set('currentModel.autocomplete.colors', colors));
  }

  function selectColor(color) {
    this.get('currentModel.product.populatedColors').addObject(color);
  }

  function selectShippingPolicy(shippingPolicy) {
    if (shippingPolicy === 'unshippable') {
      this.set('currentModel.product.unsuitable', true);
      this.set('currentModel.product.currentShippingPolicy', shippingPolicy);
      return;
    }

    const previousShippingPolicy = this.get('currentModel.product.currentShippingPolicy');
    const currentUsuitableState = this.get('currentModel.product.unsuitable');

    if (previousShippingPolicy === 'unshippable' && shippingPolicy !== 'unshippable' && currentUsuitableState === true) {
      this.set('currentModel.product.unsuitable', false);
    }

    this.set('currentModel.product.currentShippingPolicy', shippingPolicy);
  }

  function selectWeightBracket(weightBracket) {
    this.set('currentModel.product.isVisibleIncludeWeightBracketAutomation', true);
    const previousWeightBracket = this.get('currentModel.product.currentWeightBracket');
    let fieldsExcludedFromAutomation = this.get('currentModel.product.fields_excluded_from_automation') || [];

    if (weightBracket === previousWeightBracket) {
      return;
    }

    this.set('currentModel.product.currentWeightBracket', weightBracket);

    if ((0, _product2.fieldIsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, fieldsExcludedFromAutomation)) {
      return;
    }

    fieldsExcludedFromAutomation = (0, _product2.addToFieldsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, fieldsExcludedFromAutomation);
    this.set('currentModel.product.fields_excluded_from_automation', fieldsExcludedFromAutomation);
    this.set('currentModel.product.isWeightBracketExcludedFromAutomation', true);
  }

  function removeColor(color) {
    this.get('currentModel.product.populatedColors').removeObject(color);
  }

  function autocompleteTags(q) {
    if (Ember.isEmpty(q)) return;
    return _tag.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    }).then(tags => this.set('currentModel.autocomplete.tags', tags));
  }

  function selectTag(tag) {
    this.get('currentModel.product.populatedTags').addObject(tag);
  }

  function removeTag(tag) {
    this.get('currentModel.product.populatedTags').removeObject(tag);
  }

  function debouncedAutocompleteDeliveryTags(q) {
    return _productDeliveryTag.default.find({
      label: {
        $regex: `^${(0, _escapeRegex.default)(q)}`,
        $options: 'i'
      },
      limit: 10
    }).then(({
      productDeliveryTags
    }) => {
      this.set('currentModel.autocomplete.deliveryTags', productDeliveryTags);
    });
  }

  function autocompleteDeliveryTags(q) {
    if (Ember.isEmpty(q)) return;
    Ember.run.debounce(this, debouncedAutocompleteDeliveryTags, q, 250);
  }

  function selectDeliveryTag(tag) {
    this.get('currentModel.product.populatedDeliveryTags').addObject(tag);
  }

  function removeDeliveryTag(tag) {
    this.get('currentModel.product.populatedDeliveryTags').removeObject(tag);
  }

  function setQS(val) {
    let qs = Number(val);
    this.set('currentModel.product.qs', qs);
  }

  function setGTIN(val) {
    this.set('currentModel.product.gtin', val);
  }

  function setShippingCostAddition(value) {
    this.set('currentModel.product.shipping_cost_addition', {
      amount: Number(value * 100),
      amount_shown: Number(value),
      currency: 'GBP'
    });
  }

  function setExperimentQuantity(val = 0) {
    let experimentQuantity = Number(val);
    this.set('currentModel.product.experiment_quantity', experimentQuantity);
  }

  function parseCurrencyToDBFormat(shipping_cost_addition) {
    return _objectSpread(_objectSpread({}, shipping_cost_addition), {}, {
      amount: Number((shipping_cost_addition.amount_shown * 100).toFixed(0))
    });
  }

  function save() {
    let task = this.paramsFor('products').task;
    let product = this.get('currentModel.product'); // From objects to strings

    let colors = (product.populatedColors || []).map(c => c._id);
    let tags = (product.populatedTags || []).map(t => t._id);
    let delivery_tags = product.populatedDeliveryTags || [];
    let categories = this.get('currentModel.categoriesTrees').map(c => {
      return c.child ? c.child._id : c.parent._id;
    });
    let standard_manufacturer = Ember.getWithDefault(product, 'populatedManufacturer._id', null);
    let shipping_policy = product.currentShippingPolicy || null;
    let weight_bracket = product.currentWeightBracket || null;
    let fields_excluded_from_automation = product.fields_excluded_from_automation || [];
    this.set('currentModel.product.isVisibleIncludeWeightBracketAutomation', (0, _product2.fieldIsExcludedFromAutomation)(_fieldExcludedFromAutomation.default.WEIGHT_BRACKET, fields_excluded_from_automation));
    let shipping_cost_addition = parseCurrencyToDBFormat(product.shipping_cost_addition) || null;
    product.setProperties({
      colors,
      tags,
      delivery_tags,
      categories,
      standard_manufacturer,
      shipping_policy,
      weight_bracket,
      shipping_cost_addition,
      fields_excluded_from_automation
    });
    return product.save({
      task
    }).then(() => {
      this.notify.show('success', 'Product saved');
      product.setProperties({
        shipping_cost_addition: _objectSpread(_objectSpread({}, product.shipping_cost_addition), {}, {
          amount_shown: product.shipping_cost_addition.amount / 100
        })
      });
    }).catch(handleErrorOnSave.bind(this));
  }

  function saveAndNext(product) {
    return save.call(this).then(() => {
      this.modelFor('products').actions.nextProduct(product);
    });
  }

  function edit() {
    const product = this.currentModel.product;
    const boutique = this.currentModel.product.boutique._id;
    let productURL = `${_environment.default.retailURL}/${product.brand}/boutiques/${boutique}/products/edit/${product._id}?usePromenadeV2=true`;
    let win = window.open(productURL, '_blank');
    win.focus();
  }

  function view() {
    const product = this.currentModel.product;
    const boutique = this.currentModel.product.boutique._id;
    let productURL = `${_environment.default.trouvaWebURL}/boutique/${boutique}/${product.slug}`;
    let win = window.open(productURL, '_blank');
    win.focus();
  }
  /**
   * Trigger the detachFromFeed method on the product model
   * @return {Promise}
   */


  function detachFromFeed() {
    if (!confirm('Are you sure you want to detach, archive and mark this product as overwritten?')) {
      return;
    }

    const product = this.get('currentModel.product');
    return product.detachFromFeed().then(() => this.notify.show('success', 'Product detached from feed and archived')).catch(err => this.notify.show('error', err.message));
  }

  function handleErrorOnSave(error) {
    let notificationMessage = error.message;
    const responseJSONErrorExists = error && error.responseJSON && error.responseJSON.errors && error.responseJSON.errors.length;

    if (!responseJSONErrorExists) {
      this.notify.show('error', notificationMessage);
      return;
    }

    let product = this.currentModel.product;
    const responseJSONError = error.responseJSON.errors[0];

    switch (responseJSONError.detail) {
      case 'Unable to change weight bracket of product with delivery tag of type \'dropship\'':
        product.setProperties({
          weight_bracket: product.initialWeightBracket,
          currentWeightBracket: product.initialWeightBracket
        });
        notificationMessage = 'Unable to change weight bracket on a product with dropship delivery tag';
    }

    this.notify.show('error', notificationMessage);
  }
});