define("backalley/pods/components/boutique-reservation-item/component", ["exports", "moment", "backalley/enums/reservation-boutique-status-type", "backalley/config/environment"], function (_exports, _moment, _reservationBoutiqueStatusType, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const statusLabels = [];
  statusLabels[_reservationBoutiqueStatusType.default.CREATING] = 'Not (yet) visible to boutique';
  statusLabels[_reservationBoutiqueStatusType.default.ACTIVE] = 'Boutique needs to process';
  statusLabels[_reservationBoutiqueStatusType.default.PRINT] = 'Boutique needs to print docs';
  statusLabels[_reservationBoutiqueStatusType.default.COLLECTION] = 'Awaiting courier collection';
  statusLabels[_reservationBoutiqueStatusType.default.CLICK_AND_COLLECT] = 'Awaiting customer collection';
  statusLabels[_reservationBoutiqueStatusType.default.RETURN] = 'Awaiting return';
  statusLabels[_reservationBoutiqueStatusType.default.COMPLETED] = 'Boutique is done';
  statusLabels[_reservationBoutiqueStatusType.default.FAIL_PAYMENT] = 'Will not be seen by boutique';

  var _default = Ember.Component.extend({
    classNames: ['boutique-reservation-item'],
    attributeBindings: ['bIndex:tabindex'],
    editMode: false,
    globals: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.computed.alias('session.user'),
    orderFailedPayment: Ember.computed.equal('order.status', 'fail payment'),
    orderTookPayment: Ember.computed.not('orderFailedPayment'),

    updateReturnLabelCountAction() {},

    boutiqueStatus: Ember.computed.alias('boutique.status'),
    estimatedCollectionDate: Ember.computed('boutique.estimated_collection_date', function () {
      const date = this.get('boutique.estimated_collection_date');
      return date ? (0, _moment.default)(date).format('YYYY-MM-DD') : null;
    }),
    boutiqueStatusLabel: Ember.computed('boutiqueStatus', function () {
      const boutiqueStatus = this.boutiqueStatus;
      return statusLabels[boutiqueStatus] ? statusLabels[boutiqueStatus] : boutiqueStatus;
    }),
    returnsMessage: Ember.computed('order,boutique.reservations.0.boutique', function () {
      const order = this.order;
      const boutique = Ember.get(this, 'boutique.reservations.0.boutique');
      const address_line_1 = Ember.getWithDefault(boutique, 'address_line_1', '');
      const address_line_2 = Ember.getWithDefault(boutique, 'address_line_2', '');
      const county = Ember.getWithDefault(boutique, 'address_county', '');
      const city = Ember.getWithDefault(boutique, 'address_city', '');
      const postcode = Ember.getWithDefault(boutique, 'postcode', '');
      const address_postcode = Ember.getWithDefault(boutique, 'address_postcode', postcode);
      const addressFormatted = `Trouva return ${order.ordernumber}\n${boutique.name}\n${address_line_1 ? address_line_1 + '\n' : ''}${address_line_2 ? address_line_2 + '\n' : ''}${county ? county + '\n' : ''}${city ? city + '\n' : ''}${address_postcode}`;
      return addressFormatted;
    }),
    boutiqueNotesFile: Ember.computed('boutique.reservations.0.boutique', function () {
      const boutique = Ember.get(this, 'boutique.reservations.0.boutique');
      return `boutiques/${boutique._id}`;
    }),
    reservationsByStatus: Ember.computed('boutique.reservations.@each.status', function () {
      const reservations = Ember.get(this, 'boutique.reservations');
      return reservations.reduce((acc, curr) => {
        const relatedReservation = acc.find(res => res.status === curr.status && res.stock_line._id === curr.stock_line._id);

        if (relatedReservation) {
          relatedReservation.quantity += curr.quantity;
          relatedReservation.price_total += curr.transaction_price.amount;
          relatedReservation.reservationGroup.push(curr);
        } else {
          acc.push(_objectSpread(_objectSpread({}, curr), {}, {
            reservationGroup: [curr]
          }));
        }

        return acc;
      }, []);
    }),
    actions: {
      moveToPrint(date) {
        const reservationBoutique = this.boutique;
        reservationBoutique.setProperties({
          printed_shipping_label: true,
          estimated_collection_date: date
        });
        this.set('editMode', false);
        return this.setOnReservationBoutique(reservationBoutique);
      },

      setAsCollected() {
        const reservationBoutique = this.boutique;
        reservationBoutique.setProperties({
          marked_as_collected: true,
          marked_as_collected_at: Date.now()
        });
        return this.setOnReservationBoutique(reservationBoutique);
      },

      setAsNotCollected() {
        const reservationBoutique = this.boutique;
        reservationBoutique.setProperties({
          marked_as_collected: false,
          marked_as_collected_at: ''
        });
        return this.setOnReservationBoutique(reservationBoutique);
      },

      openBoutiqueOrderIframePreview() {
        const boutiqueReservation = this.boutique;
        const boutiqueName = Ember.get(boutiqueReservation, 'reservations.0.boutique.name');
        const brandId = Ember.get(boutiqueReservation, 'reservations.0.boutique.brand');
        const boutiqueId = Ember.get(boutiqueReservation, 'reservations.0.boutique._id');
        const ordernumber = Ember.get(boutiqueReservation, 'reservations.0.channel_specific.order_reference');
        const url = `${_environment.default.retailURL}/${brandId}/boutiques/${boutiqueId}/orders/active?ordernumber=${ordernumber}&usePromenadeV2=true`;
        const title = boutiqueName;
        this.globals.openIframePreview(title, url);
      },

      changeShipmentForDisplay(shipmentId) {
        const reservationBoutique = this.boutique;
        reservationBoutique.changeDisplayedShipment(shipmentId);
      },

      updateShippingProvider(provider) {
        const reservationBoutique = this.boutique;
        reservationBoutique.shippingProviderAssignToSelf(provider);
        this.setOnReservationBoutique(reservationBoutique);
      }

    }
  });

  _exports.default = _default;
});