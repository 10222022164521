define("backalley/pods/components/boutique-form/logistics-info/component", ["exports", "backalley/config/environment", "moment", "backalley/enums/shipping-charge-per-currency-type", "@streethub/calcs", "countries-and-timezones"], function (_exports, _environment, _moment, _shippingChargePerCurrencyType, _calcs, _countriesAndTimezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: {},
    isShippingFeeModalOpen: false,
    operationalStatusOptions: ['paused', 'partial', 'operational'],

    searchCountries() {},

    isOnHoliday: Ember.computed.alias('boutique.isOnHoliday'),
    holidayTo: Ember.computed.alias('boutique.holiday_to'),
    holidayFrom: Ember.computed.alias('boutique.holiday_from'),
    hasPlannedHoliday: Ember.computed.and('holidayTo', 'holidayFrom'),
    isPastHoliday: Ember.computed('holidayTo', 'isOnHoliday', function () {
      if (this.isOnHoliday || !this.holidayTo) {
        return false;
      }

      return (0, _moment.default)(this.holidayTo).isBefore((0, _moment.default)());
    }),
    isFutureHoliday: Ember.computed('holidayFrom', 'isOnHoliday', function () {
      if (this.isOnHoliday || !this.holidayFrom) {
        return false;
      }

      return (0, _moment.default)().isBefore((0, _moment.default)(this.holidayFrom));
    }),
    waiveFulfilmentFee: Ember.computed('boutique.enable_fix_charge', {
      get() {
        return !this.boutique.enable_fix_charge;
      },

      set(_, waiveFulfilment) {
        Ember.set(this.boutique, 'enable_fix_charge', !waiveFulfilment);

        if (waiveFulfilment) {
          Ember.set(this.boutique, 'fix_shipping_charge', 0);
          Ember.set(this, 'isShippingFeeModalOpen', true);
          return waiveFulfilment;
        }

        Ember.set(this.boutique, 'fix_shipping_charge', _shippingChargePerCurrencyType.default[this.boutique.currency]);
        return waiveFulfilment;
      }

    }),
    fixShippingPrice: Ember.computed('boutique.{fix_shipping_charge,enable_fix_charge}', {
      get() {
        if (!this.boutique.enable_fix_charge) {
          return 0;
        }

        return this.boutique.fix_shipping_charge ? this.boutique.fix_shipping_charge / 100 : null;
      },

      set(_, value) {
        let parsedValue = parseFloat(value, 10);

        if (isNaN(parsedValue)) {
          parsedValue = 0;
        }

        let {
          val: valueInPences
        } = _calcs.default.inPences({
          val: _calcs.default.math.eval(`${parsedValue}` * 100)
        });

        this.set('boutique.fix_shipping_charge', valueInPences);
        return value;
      }

    }),
    disableTimezoneDropdown: Ember.computed('boutique.populatedCountry', function () {
      return !this.boutique.populatedCountry || !this.boutique.populatedCountry.iso_code;
    }),
    timezones: Ember.computed('boutique', 'disableTimezoneDropdown', function () {
      if (!this.disableTimezoneDropdown) {
        return Object.values(_countriesAndTimezones.default.getTimezonesForCountry(this.boutique.populatedCountry.iso_code));
      }

      return [];
    }),
    selectedTimezone: Ember.computed('disableTimezoneDropdown', 'boutique', {
      get() {
        if (!this.disableTimezoneDropdown) {
          return this.timezones.find(timezone => this.boutique.timezone === timezone.name);
        }

        return {};
      },

      set(_, timezone) {
        return timezone;
      }

    }),
    actions: {
      editBoutiqueAction() {
        const boutique = this.boutique;
        let boutiqueURL = `${_environment.default.retailURL}/${boutique.brand._id}/boutiques/${boutique._id}?usePromenadeV2=true`;
        let win = window.open(boutiqueURL, '_blank');
        win.focus();
      },

      confirmWaiveFee() {
        this.toggleProperty('isShippingFeeModalOpen');
      },

      onCloseClick() {
        this.set('waiveFulfilmentFee', false);
        this.toggleProperty('isShippingFeeModalOpen');
      },

      setTimezone(timezone) {
        Ember.set(this.boutique, 'timezone', timezone.name);
        Ember.set(this, 'selectedTimezone', timezone);
      }

    }
  });

  _exports.default = _default;
});