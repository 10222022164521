define("backalley/pods/main/boutiques/edit/route", ["exports", "backalley/models/boutique", "backalley/models/tag", "backalley/models/area", "backalley/models/brand", "backalley/models/country", "backalley/models/sync-integration", "backalley/util/escape-regex", "countries-and-timezones", "backalley/config/environment", "backalley/enums/p2g-services", "backalley/models/curate/payment-company"], function (_exports, _boutique, _tag, _area, _brand, _country, _syncIntegration, _escapeRegex, _countriesAndTimezones, _environment, _p2gServices, _paymentCompany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    serialize(model) {
      return {
        boutique_id: model._id
      };
    },

    model({
      boutique_id
    }) {
      const promises = [this._getBoutique(boutique_id), this._getSyncIntegration(boutique_id)];
      return Ember.RSVP.all(promises).then(([boutique, syncIntegration]) => {
        return Ember.Object.create({
          boutique,
          syncIntegration,
          brand: _brand.default.create(boutique.brand),
          tags: Ember.A(),
          areas: Ember.A(),
          p2gServiceIds: _p2gServices.p2gServiceIdsSorted,
          p2gServiceDescriptionById: _p2gServices.p2gServiceDescriptionById,
          user: this.session.user,
          imageFolder: `brand/${boutique.brand}`,
          teamNotesFile: `boutiques/${boutique_id}`
        });
      });
    },

    _getBoutique(_id) {
      return _boutique.default.find({
        _id,
        populate: 'tags,areas,address_country,brand,boutique_categories,registered_address_country'
      }).then(async boutiques => {
        var _boutique$hokodo;

        const boutique = boutiques[0];
        boutique.setProperties({
          _savedValuesForComparison: {
            eu_vat: boutique.eu_vat && _objectSpread({}, boutique.eu_vat)
          },
          populatedTags: [...boutique.tags],
          populatedAreas: [...boutique.areas],
          populatedCountry: _objectSpread({}, boutique.address_country),
          populatedRegisteredCountry: _objectSpread({}, boutique.registered_address_country),
          populatedHokodoCompany: (_boutique$hokodo = boutique.hokodo) !== null && _boutique$hokodo !== void 0 && _boutique$hokodo.company ? await _paymentCompany.default.findById(boutique.hokodo.company) : null,
          tags: (boutique.tags || []).map(tag => tag._id),
          areas: (boutique.areas || []).map(area => area._id),
          metrics: boutique.metrics || {},
          address_country: Ember.get(boutique, 'address_country._id'),
          registered_address_country: Ember.get(boutique, 'registered_address_country._id')
        });
        return boutique;
      });
    },

    async _getSyncIntegration(boutiqueId) {
      const query = {
        'settings.boutique': boutiqueId,
        limit: 1,
        fields: '_id status'
      };
      const {
        syncIntegrations
      } = await _syncIntegration.default.findWithMeta(query);
      const syncIntegration = syncIntegrations[0] || undefined;

      if (syncIntegration) {
        syncIntegration._id = Ember.getWithDefault(syncIntegration, '_id', undefined);
        syncIntegration.status = Ember.getWithDefault(syncIntegration, 'status', undefined);
      }

      return syncIntegration;
    },

    _updateBrand(brand) {
      return brand.save();
    },

    _updateBoutique(boutique) {
      let tags = boutique.get('populatedTags').map(tag => tag._id);
      let areas = boutique.get('populatedAreas').map(area => area._id);
      boutique.set('tags', tags);
      boutique.set('areas', areas);

      if (Ember.isEmpty(boutique.support_email)) {
        boutique.set('support_email', null);
      }

      if (Ember.isEmpty(boutique.order_email)) {
        boutique.set('order_email', null);
      }

      if (Ember.isEmpty(boutique.statement_email)) {
        boutique.set('statement_email', null);
      }

      if (Ember.isEmpty(boutique.primary_email)) {
        boutique.set('primary_email', null);
      }

      return boutique.save();
    },

    actions: {
      editBoutiqueAction() {
        const {
          boutique
        } = this.currentModel;
        const boutiqueURL = `${_environment.default.retailURL}/${boutique.brand._id}/boutiques/${boutique._id}?usePromenadeV2=true`;
        const win = window.open(boutiqueURL, '_blank');
        win.focus();
      },

      searchTags(q) {
        if (Ember.isEmpty(q)) return;
        return _tag.default.find({
          label: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          },
          limit: 10
        }).then(tags => this.set('currentModel.tags', tags));
      },

      deleteTag(tag) {
        let boutiqueTags = this.currentModel.boutique.populatedTags.filter(bTag => bTag._id !== tag._id);
        this.set('currentModel.boutique.populatedTags', boutiqueTags);
      },

      addTag(tag) {
        let currentTags = this.currentModel.boutique.populatedTags;

        if (!currentTags.findBy('_id', tag._id)) {
          // prevent adding the same twice
          currentTags.addObject(tag);
          this.set('currentModel.boutique.populatedTags', currentTags);
        }
      },

      searchAreas(q) {
        if (Ember.isEmpty(q)) {
          return;
        }

        return _area.default.find({
          name: {
            $regex: `^${(0, _escapeRegex.default)(q)}`,
            $options: 'i'
          },
          limit: 10
        }).then(areas => this.set('currentModel.areas', areas));
      },

      deleteArea(area) {
        let boutiqueAreas = this.currentModel.boutique.populatedAreas.filter(bArea => bArea._id !== area._id);
        this.set('currentModel.boutique.populatedAreas', boutiqueAreas);
      },

      addArea(area) {
        let currentAreas = this.currentModel.boutique.populatedAreas;

        if (!currentAreas.findBy('_id', area._id)) {
          // prevent adding the same twice
          currentAreas.addObject(area);
          this.set('currentModel.boutique.populatedAreas', currentAreas);
        }
      },

      searchCountries(name = '+') {
        return _country.default.find({
          name: {
            $regex: '^' + name,
            $options: 'i'
          }
        });
      },

      setCountry(country) {
        if (!country || !country._id) return;
        this.set('currentModel.boutique.populatedCountry', country);
        this.set('currentModel.boutique.address_country', country._id);

        const [{
          name: timezone
        }] = _countriesAndTimezones.default.getTimezonesForCountry(country.iso_code);

        this.set('currentModel.boutique.timezone', timezone);
      },

      back() {
        this.transitionTo('main.boutiques');
      },

      updateBoutiqueAndBrand() {
        const boutique = this.currentModel.boutique;
        const brand = this.currentModel.brand;
        const promises = [this._updateBoutique(boutique), this._updateBrand(brand)];
        return Ember.RSVP.all(promises).then(() => this.transitionTo('main.boutiques')).catch(err => this.notify.show('err', err.message));
      },

      quickPublish() {
        return this.currentModel.boutique.quickPublish().then(() => this.notify.show('success', 'Publishing now')).catch(() => this.notify.show('err', 'Unable to to publish'));
      }

    }
  });

  _exports.default = _default;
});