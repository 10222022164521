define("backalley/pods/components/prospective-boutique/component", ["exports", "backalley/config/environment", "backalley/models/user"], function (_exports, _environment, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    signedBy: Ember.computed('prospectiveBoutique.signed_by', function () {
      return _user.default.findWithMeta({
        _id: this.prospectiveBoutique.signed_by
      }).then(({
        users
      }) => {
        const [user] = users;
        return user;
      });
    }),
    linkToBoutique: Ember.computed('prospectiveBoutique.boutique', function () {
      const {
        boutique
      } = this.prospectiveBoutique;
      return `${_environment.default.retailURL}/${boutique.brand}/boutiques/${boutique._id}/terms-and-conditions?usePromenadeV2=true`;
    }),
    ometriaURL: Ember.computed('prospectiveBoutique.email', function () {
      const {
        email
      } = this.prospectiveBoutique;
      const url = ['https://app.ometria.com/app/23e5c9f4e06d0b8e/segments?state=', // Filter the contact list to only show this customer
      encodeURIComponent(`{"reportOffset":0,"query":{"filters":[{"value":["${email}"],"op":"eq","field":"email"}],"group":"all","query_type":"and"}}`), // Opens the Ometria dashboard to the second tab that shows the contact
      '#1'].join('');
      return url;
    })
  });

  _exports.default = _default;
});